import axios from "axios";
import React, {useEffect, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "./App.css";
import { BACKEND_API_URL } from "./config";

function MainPage() {
  const navigate = useNavigate();
  const [curResults, setCurResults] = useState<any[]>([]);
  const [curState, setCurState] = useState(false)
  
  const location = useLocation();
  let initStr = new URLSearchParams(location.search).get("prompt");

  const handleConversation = () => {
    if(curState === false)
      setCurState(true)
    else
      navigate("/");
  };

  useEffect(() => {
    getResult()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getResult = async () => {
    let curCount = 0;

    while(curState === false) {
      let strAPIURL = ""
      if(curCount % 2 === 0)
        strAPIURL = BACKEND_API_URL + "/openai";
      else
      strAPIURL = BACKEND_API_URL + "/googlepalm";

      try {
        const requestData = {
          inputStr: initStr
        }
        const response = await axios.post(strAPIURL, requestData);
        let resultStr = response.data;

        setCurResults(curResults => [...curResults, resultStr]);

        curCount++;
        if (((resultStr.toLowerCase().includes('okay') && resultStr.toLowerCase().indexOf('okay') === 0) || 
                resultStr.toLowerCase().includes('sure')) && resultStr.toLowerCase().indexOf('sure') === 0) {
            let updateStr = resultStr.slice(5);
            initStr = updateStr;
        } else {
            initStr = resultStr;
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-main">
          <div className="logo-main">
            <img src="/image/logo.png" alt="" className="logo-image" />
            <div className="logo-text">Experients</div>
          </div>
          <div className="more-experiements">More Experiments {">"}</div>
          <div className="logo-btn">
            <img src="/image/logobtn.png" alt="" />
          </div>
        </div>
        <div className="conversation-main">
          <div className="conversation-title">
            <strong>Prompt:</strong>&nbsp;{initStr}
          </div>
          <div className="conversation-content">
            {curResults.map((result, key) => (
              <div className="conversation-gpt" key={key}>
                <div className="conversation-gpt-header">
                  <img src={key % 2 === 0 ? "/image/chatgpt-icon.svg" : "/image/Google_Bard_logo.svg" }  alt="" />
                  <span className="conversation-gpt-title">{key % 2 === 0 ? "ChatGPT" : "Bard" }</span>
                </div>
                <div>
                  <span className="conversation-gpt-content">
                    {result}
                  </span>
                </div>
              </div>
            ))}
            {/* <div className="conversation-gpt">
              <div className="conversation-gpt-header">
                <img src="/image/Google_Bard_logo.svg" alt="" />
                <span className="conversation-gpt-title">Bard</span>
              </div>
              <div>
                <span className="conversation-gpt-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  nec rutrum sem. Integer non hendrerit dui. Aliquam id volutpat
                  quam. Morbi scelerisque nibh non tortor dapibus tempor.
                </span>
              </div>
            </div> */}
          </div>
          <div className="conversationBtn-main">
            <span className="contentBtn-title">{curResults.length % 2 === 0 ? "ChatGPT is Thinking..." : "Bard is Thinking..." }</span>
            <div>
              <button className="conversationBtn" onClick={handleConversation}>
                {curState === false ? "Stop conversation" : "New conversation"}
              </button>
            </div>
          </div>
        </div>
        <div className="footer-main">
          <span className="footer-text">©2023 Reshape Creative</span>
        </div>
      </header>
    </div>
  );
}

export default MainPage;
